.App {
  position: relative;
}

.Sidebar__dimmed {
  height: 100vh;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.Sidebar__Pusher::-webkit-scrollbar {
  display: none;
}
