.InfoMessageWrapper {
  top: 15px;
  left: 15px;
  right: 15px;
  position: absolute;
  z-index: 10;
}

.InfoMessageWrapperRelative {
  position: relative;
  margin-bottom: 20px;
}
